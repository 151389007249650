import React, { Component } from "react";
import { graphql, StaticQuery } from "gatsby";
import Styles from '../../assets/styles/funnel.module.css'
// import goldBrokerImage from '../../assets/images/gold-broker.png'
import goldIcon from "../../assets/images/gold-broker.png";
import premiumIcon from "../../assets/images/premium-broker.png";
import cx from 'classnames';
import locationImg from '../../assets/images/locationImg.png'
// import brokerImage from '../../assets/images/broker-img.png'
import { log, host, filterByState, formatResponseTime } from '../../helpers/commons'
import Axios from "axios";

class SingleBroker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            brokerID: '',
            unmoundChk: true 
        }
    }

    componentDidMount() {
        let brokerId = '';
        Axios.get(host + '/gatsby-api/all-active-brokers-id?brokers=realtime').catch((error) => {
            console.error(error);
        }).then((response) => {
            if (response) {
                brokerId = response.data.brokersId;
                if (brokerId !== undefined && !!this.state.unmoundChk) {
                    this.setState({
                        brokerID: brokerId
                    });
                }
            } else {
                console.log('no response');
            }
        })
    }

    componentWillUnmount() {
        this.setState({
            unmoundChk: false
        })
    }


    render() {

        let broker = [];

        let hideSearchLink = false;

        console.log("In single broker display..")

        console.log(this.props);

        if (!this.props.funnelData) {
            return (<div></div>)
        }

        let brokers = this.props.data.allHashchingBroker.edges;
        let searchArea = '';
        let showNorFoundMessage = false;

        //console.log(this.props);

        if (!!this.props.currentState.broker && !this.props.postcode) {

            let brokerId = this.props.currentState.broker || ''

            // log(this.props.funnelData.postcode, "current props");

            let statePostcode = this.props.funnelData.postcode;

            if (typeof (Storage) !== "undefined" && sessionStorage.dealBrokers) {

                let dealBrokers = sessionStorage.dealBrokers
                
                if (dealBrokers) {
                    dealBrokers = dealBrokers.split(",");
                    brokers = brokers.filter(({ node }) => {
                        return dealBrokers.indexOf(node.broker_id.toString()) != -1;
                    })

                    if(brokers.length == 1) {
                        hideSearchLink = true;
                    }
                }
            }
            
            broker = brokers.filter((node) => {
                if (!!statePostcode && node.node.broker_id === brokerId) {
                    let broker_postcode = node.node.broker_postcode.split(",");
                    if(broker_postcode.indexOf(statePostcode.toString()) == -1) {
                        showNorFoundMessage = true;
                    }
                }
                return node.node.broker_id === brokerId
            })

            //console.log(showNorFoundMessage, "showNorFoundMessage")

        } else {
            if (this.props.postcode) {
                let postcode = this.props.postcode.split(',');
                

                if (postcode.length == 3 && postcode[0] !== '') {

                    if (!!this.state.brokerID && typeof (Storage) !== "undefined" && sessionStorage.dealBrokers) {
                        let dealBrokers = sessionStorage.dealBrokers
                        let allDealBrokers = [];
                        if (dealBrokers) {

                            dealBrokers = dealBrokers.split(",");
                            // console.log(dealBrokers);
                            allDealBrokers = brokers.filter(({ node }) => {
                                // console.log(dealBrokers.indexOf(node.broker_id.toString()))
                                return dealBrokers.indexOf(node.broker_id.toString()) != -1;
                            })
                            // console.log("deal brokers");
                            // console.log(allDealBrokers);
                        }

                        // filter only if we have more then one broker
                        // new requirement
                        if (dealBrokers.length > 1) {
                            
                            if (!!this.state.brokerID) {
                                let brokerID = this.state.brokerID.split(",");
                                allDealBrokers = allDealBrokers.filter(({ node }) => {
                                    return brokerID.indexOf(node.broker_id.toString()) != -1;
                                })
                            }

                            log(allDealBrokers, "All deal brokers");

                            broker = allDealBrokers.filter(({ node }) => {
                                let broker_postcode = node.broker_postcode.split(",");
                                return broker_postcode.indexOf(postcode[0].toString()) != -1;
                            })


                            log(broker, "brokers after postcode filter");

                            if (broker.length == 0) { //broker is not found in the postcode user has selected
                                log(postcode[2]);
                                let stateCode = postcode[2];
                                
                                broker = filterByState(allDealBrokers, stateCode)

                                log(broker, "brokers after state filter");

                                if (broker.length > 0) {
                                    searchArea = stateCode
                                    showNorFoundMessage = true;
                                }

                                if (broker.length == 0) {
                                    log(broker, "national brokers");
                                    broker = allDealBrokers;
                                    searchArea = 'Australia'
                                    showNorFoundMessage = true;
                                }
                            }

                        } else {
                            hideSearchLink = true;
                            broker = allDealBrokers;
                        }

                    } else {

                        if (!!this.state.brokerID) {

                            let brokerID = this.state.brokerID.split(",");
                            brokers = brokers.filter(({ node }) => {
                                return brokerID.indexOf(node.broker_id.toString()) != -1;
                            })
                             
                            broker = brokers.filter(({ node }) => {
                                let broker_postcode = node.broker_postcode.split(",");
                                return broker_postcode.indexOf(postcode[0].toString()) != -1;
                            })

                            if (broker.length == 0) { //broker is not found in the postcode user has selected

                                let stateCode = postcode[2];

                                broker = filterByState(brokers, stateCode)


                                if (broker.length > 0) {
                                    searchArea = stateCode
                                    showNorFoundMessage = true;
                                }
                                
                                if (broker.length == 0) {
                                    broker = brokers;
                                    searchArea = 'Australia'
                                    showNorFoundMessage = true;
                                }
                            }
                        }
                    }
                }
            }
        }


        let data = []

        if (broker) {
            if (this.props.hideSection && !this.props.isHidden) {
                this.props.hideSection();
            }
            data = broker;
        }

        console.log(data);

        if (!data.length && !!this.state.brokerID) {
            let postcode = this.props.postcode.split(',');
            if (postcode.length == 3) {
                return (<div className={cx('text-center col-sm-12')}>

                    <p className={'alert alert-danger'}>Can't find a broker in your area? Don't worry, some of our brokers work remotely. <br /> Contact our support team on 1800 444 744 or support@hashching.com.au to submit your enquiry.
                </p>
                </div>)
            }
            return (<div></div>)
        }

        if (!data.length) {
            return (<div className={cx('singleBrokrLoader loading')}></div>)
        }

        const brokerSingle = data[0].node

        

        let premiumImg;

        if (brokerSingle.premium === 3) {
            premiumImg = <img
                src={goldIcon}
                alt="hashching-gold-broker"
                title="hashching-gold-broker"
            />
        } else if (brokerSingle.premium === 1) {
            premiumImg = <img
                src={premiumIcon}
                alt="hashching-premium-broker"
                title="hashching-premium-broker"
            />
        }

        let postcode = this.props.funnelData.postcode

        let suburb = this.props.funnelData.suburb

        let state = this.props.funnelData.state

        if (this.props.arPostcode) {
            postcode = this.props.arPostcode[0];
            suburb = this.props.arPostcode[1];
            state = this.props.arPostcode[2];
        }

        if (brokerSingle.broker_details.suburb) {
            brokerSingle.location = `${brokerSingle.broker_details.suburb}`
        } else {
            brokerSingle.location = `${brokerSingle.state}`
        }

        if (suburb) {
            brokerSingle.oLocation = `${postcode}, ${suburb}, ${state}`
        } else {
            brokerSingle.oLocation = `${postcode}, ${state}`
        }

        let message = "Sorry, we couldn't find a broker in your area but we have found you top brokers outside your area who can still assist you.";

        return (
            <div className={cx('col-sm-12', Styles.singleBrokerContact)}>
            
                {!!showNorFoundMessage && <div className={Styles.notfoundbrokr}>{message}</div>}

                <div className={Styles.contctbrokrContnt}>
                    <div className={cx('card', Styles.card)}>
                        <div className={cx('card-body', Styles.cardBody)}>
                            <div className={cx('text-center', Styles.brokrDetailInnr)}>
                                <h4 className={cx(Styles.brokrPlace)}>Your broker advisor in <a >{`${brokerSingle.oLocation}`}</a> </h4>
                                <img className={cx(Styles.brokrImg)} src={brokerSingle.profile_pic} alt="broker-img" />
                                <h4 className={cx(Styles.brokrNameStatus)}>
                                    <span className={cx(Styles.brokrName)}>{`${brokerSingle.firstName} ${brokerSingle.lastName}`}</span>
                                    <span className={cx(Styles.brokrstatus)}>{premiumImg}</span>
                                </h4>
                                {(brokerSingle.review_count > '2') && (<h4 className={cx(Styles.totalReviews)}>
                                    <span className={cx(Styles.reviewRating)}>
                                        <span className={cx('badge', Styles.badge)}>
                                            {/* <i className={cx('fa', Styles.faStar)}></i> */}
                                            <svg
                                                aria-hidden="true"
                                                data-prefix="fas"
                                                data-icon="star"
                                                className={Styles.svgInlineFa}
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 576 512"
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M259.3 17.8L194 150.2 47.9 171.5c-26.2 3.8-36.7 36.1-17.7 54.6l105.7 103-25 145.5c-4.5 26.3 23.2 46 46.4 33.7L288 439.6l130.7 68.7c23.2 12.2 50.9-7.4 46.4-33.7l-25-145.5 105.7-103c19-18.5 8.5-50.8-17.7-54.6L382 150.2 316.7 17.8c-11.7-23.6-45.6-23.9-57.4 0z"
                                                />

                                            </svg>
                                            <span className={cx(Styles.ratingTxt)}>{brokerSingle.broker_rating}</span>
                                        </span>
                                    </span>
                                    <span className={cx(Styles.countReviewsMain)}><span className={cx(Styles.countReviws)}>{brokerSingle.review_count}</span><span className={cx(Styles.reviwsTxt)}> 
                                    {(brokerSingle.review_count > 1) ? 'Reviews' : 'Review' }
                                    </span></span>
                                </h4>)}
                                {(brokerSingle.review_count <= '2') && (<h4 className={cx(Styles.totalReviews, Styles.responsetimePart)}>
                                    <span className={cx(Styles.countReviewsMain, Styles.activDeals)}>
                                        <span className={Styles.countReviws}>{brokerSingle.deals_count}</span>
                                        <span className={Styles.reviwsTxt}>
                                            {'Active Deals'}
                                        </span>
                                    </span>
                                    <span className={cx(Styles.countReviewsMain, Styles.responsTime)}>
                                        <span className={Styles.countReviws}>{formatResponseTime(brokerSingle.broker_details.avgResponse)}</span>
                                        <span className={Styles.reviwsTxt}>
                                            {'Response Time'}
                                        </span>
                                    </span>
                                </h4>)}
                                <h5 className={cx(Styles.brokrLocation)}>
                                    {/* <i className={cx('fa', Styles.faMapaMrker)} aria-hidden="true"></i>  */}
                                    <img src={locationImg} alt="location" />
                                    <span className={Styles.locationTxt}>{`${brokerSingle.location}, ${brokerSingle.postcode}`}</span></h5>
                                {/* <p className={cx(Styles.brokrSumry)}>“Helpful and very good <br /> customer service”</p> */}
                                <a className={cx('btn', Styles.continueBtn)} href="javascript:void(0);" onClick={() => {
                                    this.props.continueChooseBrokerHandler(brokerSingle.broker_id, this.props.arPostcode);
                                    console.log("continue button clicked...");

                                }}>Continue</a>

                                {!hideSearchLink &&
                                    (<p className={cx(Styles.somonelseText)}> <a className={cx(Styles.somonelseLink)} href="javascript:void(0);" onClick={() => {
                                        console.log(this.props.arPostcode)
                                        this.props.preferSomeoneElseHandler(this.props.arPostcode, brokerSingle.broker_id);
                                        console.log("prefer someone else clicked");

                                    }}>Choose a different broker?</a></p>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default (props) => (
    <StaticQuery
        query={
            graphql`
        query {
            allHashchingBroker(sort: {fields: [sort_premium, tier_2, monthly_leads, review_count, broker_rating, deals_count], order: [DESC, DESC, ASC, DESC, DESC, DESC]}) {
            edges {
                node {
                    broker_id
                    user_id
                    firstName
                    lastName
                    profile_pic
                    deals_count
                    review_count
                    broker_rating
                    languages
                    premium
                    profile_view
                    specifications
                    postcode
                    state
                    stateCode
                    broker_postcode
                    broker_details {
                    state
                    suburb
                    avgResponse
                    }
                }
            }
        }
      }
      `
        }
        render={(data) => (
            <SingleBroker data={data} {...props} />
        )}
    />
)